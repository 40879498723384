/* CREATE PROPOSAL MODAL */
.create-proposal-background {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding-bottom: 23%;
    overflow: auto;
    backdrop-filter: blur(29px);
}

.create-proposal-modal {
    z-index: 2;
    color: white;
    background-color: black;
    border: 1px solid #9c9ca933;
    border-radius: 13px;
    width: 55%;
    animation: slidein 0.6s;
}

.create-proposal-form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    /* height: 100%; */
    padding: 2%;
    border-radius: 18px;
}

.create-proposal-form > * {
    padding: 2%;
}

.cpf-close-modal {
    transform: scale(2);
    fill: rgba(156, 156, 169, 0.74);
}

.cpf-close-modal:hover {
    fill: white;
}

.cpf-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.cpf-top div {
    display: flex;
    align-items: center;
}

.cpf-modal-profile {
    width: 32px;
    height: 32px;
    margin-right: 4%;
}

.cpf-account-id {
    height: 17px;
    font-size: 11px;
}

.cpf-inputs {
    background-color: transparent;
    border: none;
    color: white;
    max-width: 100%;
    min-width: 100%;
}

#cpf-title-input {
    font-size: 24px;
}

#cpf-description-input {
    font-size: 14px;
}

.cpf-bottom-mid {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cpf-bottom-mid-text {
    font-weight: 400;
    font-size: 13px;
}

.cpf-right-side {
    display: flex;
    flex-direction: row;
    width: 16%;
}

.cpf-upload-image {
    max-width: 28px;
    margin-left: 22%;
}

.cpf-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.cpf-bottom-left {
    display: flex;
    align-items: center;
    width: 50%;
}

.cpf-bottom-left-text {
    margin-right: 5%;
    font-weight: 400;
    font-size: 13px;
}

.cpf-select-planet {
    display: flex;
    /* max-width: 140px; */
    /* justify-content: space-between; */
    align-items: center;
    border: 1px solid rgba(156, 156, 169, 0.4);
    /* width: 50%; */
    padding: 2% 4%;
    /* height: 1rem; */
    font-weight: 700;
    font-size: 14px;
    line-height: 13px;
    border-radius: 100px;
    overflow: auto;
}

.cpf-overflow-container {
    overflow: auto;
}

.sumbit-proposal-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px 16px 11px 13px;
    gap: 10px;

    width: 178px;
    height: 40px;

    /* White */
    color: black;
    background: #ffffff;
    border-radius: 38px;
    transition-duration: 0.4s;
}

.sumbit-proposal-button:hover {
    background-color: black;
    border: 2px solid white;
    color: white;
}

.proposal-error-message {
    border-left: 2px solid #dd3434;
    font-size: 12px;
    margin: 0 0 4% 3%;
    padding: 0 0 0 1%;
}

/* CREATE PROPOSAL MODAL ANIMATION */
@keyframes slidein {
    from {
        margin-top: -800px;
        opacity: 0;
    }
    to {
        margin-top: 0px;
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* ADD TAG & TAGS CONTAINER */
.cpf-tags-container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    font-weight: 700;
    font-size: 12px;
    width: 50%;
}

.cpf-tag-container {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
    width: 50%;
}

#cpf-tag-input {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    color: white;
    /* padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 4%;
    padding-right: 4%; */
    width: 60px;
    outline: 0;
    font-weight: 400;
    font-size: 11px;
}

.cpf-tag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 11px;
    font-weight: 400;
    padding-top: 3%;
    padding-bottom: 3%;
    padding-left: 4%;
    padding-right: 2%;
    margin-right: 3%;
    border: 1px solid rgba(156, 156, 169, 0.2);
    border-radius: 100px;
}

.cpf-tag-close {
    height: 10px;
    fill: rgba(156, 156, 169, 0.74);
}

.cpf-tag-close:hover {
    fill: white;
}

/* MODAL DROP DOWN MENU */
.cpf-drop-menu {
    display: flex;
    flex-direction: column;
    width: 56%;
    max-width: 220px;
}

.drop-down-menu {
    width: 15%;
    max-width: 110px;
    position: absolute;
    margin-top: 5%;
    font-weight: 700;
    font-size: 16;
    height: 200px;
    overflow: auto;
}

.drop-down-option {
    padding: 15%;
    background: black;
    transition-duration: 0.4s;
    cursor: pointer;
}

.drop-down-option:hover {
    background-color: white;
    color: black;
}

.create-proposal-button > span {
    font-size: 20px;
    margin-right: 10px;
}

/* ERROR HANDLING */
.cpf-tag-errorhandling {
    /* margin-bottom: 2%; */
    font-size: 12px;
    color: red;
}

.modal-error-handling {
    font-size: 12px;
    color: red;
    padding: 0 2% 0 2%;
}

@media screen and (max-width: 797px) {
    .create-proposal-modal {
        z-index: 2;
        color: white;
        background-color: black;
        border: 1px solid #9c9ca933;
        border-radius: 13px;
        width: 96%;
        animation: slidein 0.6s;
    }

    .create-proposal-form {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        /* height: 100%; */
        padding: 2%;
        border-radius: 18px;
    }

    .sumbit-proposal-button {
        width: auto;
        font-size: 0.7em;
    }
    .cpf-bottom-mid > * {
        font-size: 0.8em;
    }
}
