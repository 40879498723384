.proposalpage-buttons > button {
  padding: 9px 23px 9px 13px;
  border: 1px solid rgba(156, 156, 169, 0.2);
  border-radius: 72px;
  margin-bottom: 16px;
  transition: 0.2s ease;
}

.proposalpage-buttons > button:hover {
  border: 1px solid #fff;
}

.back-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.009em;
  margin-left: 15%;
  color: white;
}

.proposalpage-card {
  display: flex;
}

.proposalpage-card::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.author-address {
  display: inline-block;
  border-right: 1px solid rgba(156, 156, 169, 0.74);
  padding: 0px 0.8em 0px 0.5em;
  margin-right: 0.8em;
  color: #ffffff;
  font-weight: 700;
}
.proposalpage-details {
  width: 72%;
  padding: 3%;
  min-width: 17em;
}

.proposalpage-title {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  text-align: left;
  padding: 1em 0 0.6em;
}

.moreinfo {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  padding-bottom: 2em;
  margin-bottom: 0;
  margin-top: 0;
}

.proposalsdetails-border {
  border-bottom: 1px solid rgba(156, 156, 169, 0.2);
}

.proposalpage-infosection {
  width: 30%;
  padding: 3% 3% 3% 0;
  min-width: 17em;
}

.proposalpage-infosection > *:not(:last-child) {
  margin-bottom: 5%;
}

.tags-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.tags-text:first-child {
  border-left: none;
  padding-left: 0;
}

.tags-text {
  border-left: 1px solid rgba(255, 245, 231, 0.4);
  padding-left: 4px;
  font-style: normal;
  font-weight: 300;
  font-size: 0.8em;
  line-height: 1.2;
  padding-right: 4px;
  color: #fff;
}

.tooltip-tag {
  position: relative;
  cursor: pointer;
}
.tooltip-text-tag {
  position: absolute;
  font-size: 0.8em;
  transform: translateY(-65%);
  background-color: rgb(22, 21, 21);
  color: #fff;
  backdrop-filter: blur(29px);
  border: 1px solid rgba(156, 156, 169, 0.2);
  white-space: nowrap;
  padding: 3px 7px;
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease;
}
.tooltip-tag:hover .tooltip-text-tag {
  visibility: visible;
  opacity: 1;
}

.test-card {
  box-shadow: 0px 1rem 1.5rem rgba(0, 0, 0, 0.5);
  padding: 16px;
  margin: 5px 0 0 0;
  background: rgba(44, 44, 44, 0.28);
  /* backdrop-filter: blur(29px); */
  border-radius: 13px;
  color: white;
}

.react-foldable-paragraph-v-1-0-0 {
  transition: 0.2s ease;
  white-space: break-spaces !important;
}

.react-foldable-paragraph-v-1-0-0-handler {
  font-size: 11px;
  color: rgb(243, 235, 235);
}

.react-foldable-paragraph-v-1-0-0-handler:hover {
  color: white;
}

.cannot-vote-cont {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.cannot-vote-cont h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
}

.cannot-vote-cont small {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 174%;
  color: #ffffff;
}

@media only screen and (max-width: 797px) {
  /* FILTER BAR SECTION */
  .proposalpage-card {
    display: flex;
    flex-direction: column;
  }
  .proposalpage-title {
    padding: 0.3em 0 0.3em 0;
  }
  .proposalpage-details {
    width: 100%;
  }
  .moreinfo {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    padding: 0;
  }

  .proposalsdetails-border {
    border-bottom: none;
  }
  .proposalpage-infosection {
    padding: 1.4em 0 0.3em 0;
    width: 100%;
  }
  .react-foldable-paragraph-v-1-0-0 {
    transition: 0.2s ease;
    white-space: break-spaces !important;
  }
}
