.button {
    background: #ffffff;
    border-radius: 38px;
    color: black;
    cursor: pointer;
    transform: scale(1);
    transition: 0.2s ease;
}

.button:hover,
.button:focus {
    transform: scale(1.05);
}

@keyframes bounce {
    10%, 90% {
    transform: translate3d(0, -1px, 0);
    }
    
    20%, 80% {
    transform: translate3d(0, 2px, 0);
    }

    30%, 50%, 70% {
    transform: translate3d(0, -4px, 0);
    }

    40%, 60% {
    transform: translate3d(0, 4px, 0);
    }
}

.nudge-btn {
    animation: bounce 0.82s cubic-bezier(.36,.07,.19,.97) both;
}

.widget-padding {
    padding: 13.6552px 19.8621px;
}

.header-padding {
    padding: 10px 16px;
}

span {
    display: flex;
    flex-direction: row;
    align-items: center;
}

span > img {
    margin-right: 5px;
}

.inactive {
    padding-top: 1px;
    padding-left: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.009em;
    /* Black */

    color: #000000;
}

.connect-active {
    flex-direction: row-reverse;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;
    letter-spacing: -0.009em;
    margin-left: 14px;
}
.connect-active > span {
    padding-right: 13px;
    padding-top: 12px;
}

.connect-active > img {
    width: 39px;
    height: 39px;
}
/* Modal */
.create-connectmodal-background {
    display: flex;
    justify-content: center;
    align-items: start;
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(44, 44, 44, 0.28);
    border: 1px solid rgba(156, 156, 169, 0.2);
    backdrop-filter: blur(29px);
    padding-top: 10%;
}

.create-connect-modal {
    color: white;
    background-color: white;
    border: 1px solid #9c9ca933;
    border-radius: 13px;
    box-shadow: 0px 10px 50px hsla(0, 0%, 0%, 0.33);
    width: 300px;
    border-radius: 13px;
    padding: 20px;
    animation: slidein 0.6s;
}

.connect-buttons {
    margin-left: 15px;
}

.connect-buttons > button {
    border: 1px solid #9c9ca933;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    margin: 10px 0 0 0;
}

.closeX {
    float: right;
    cursor: pointer;
    margin: -2px 0 0 4px;
    color: #000000;
    border: 1px solid #9c9ca933;
    padding: 1px 4px;
    border-radius: 50%;
}

.closeX:hover {
    background: red;
    color: white;
}

.select {
    padding: 0 0 10px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 16px;
    letter-spacing: -0.009em;
    color: #000000;
}

.btn {
    padding: 8px 25px;
}

.btn-wc {
    padding: 8px 29px;
}

.btn-meta {
    padding: 8px 50px;
}

.btn:hover,
.btn-wc:hover,
.btn-meta:hover {
    background: rgba(44, 44, 44, 0.28);
}

.btn-text {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    letter-spacing: -0.009em;
    color: #000000;
    padding-top: 2px;
    padding-left: 5px;
}
