/* PROPOSAL CARDS */
.proposal-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #9c9ca933;
    border-radius: 13px;
    background-color: #2c2c2c47;
    padding: 3%;
    margin-bottom: 1%;
    -webkit-backdrop-filter: blur(29px);
    backdrop-filter: blur(29px);
    transition: 0.2s ease;
}
.details-container-left {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.details-container-right {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.dcr-date {
    border-right: 1px solid rgba(156, 156, 169, 0.74);
    margin-right: 0.8em;
    padding: 0px 0.8em 0px 0em;
    font-size: 10px;
}
.expires-at {
    font-weight: 400;
    font-size: 10px;
    padding: 0 7px 0 0;
    margin-right: 7px;
    border-right: 1px solid rgba(156, 156, 169, 0.74);
}
.created-at {
    font-weight: 400;
    font-size: 10px;
}
.details-container > *:first-child {
    font-weight: initial;
}

.flex-middleitem-left .details-container {
    justify-content: flex-start;
}

.flex-middleitem-right .details-container {
    justify-content: flex-end;
}

.card-proposal-status {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.image-address-container {
    display: flex;
    align-items: center;
}
#card-profile {
    height: 20px;
}
.mid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 22px 0 24px 0;
    grid-gap: 3%;
}
.bottom {
    width: 100%;
}
.card-proposal-summary {
    width: 70%;
}
.card-proposal-voting {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    background: rgba(112, 112, 124, 0.2);
    border-radius: 6px;
    padding: 2%;
    min-width: 16em;
    height: fit-content;
}
.card-title {
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    padding-bottom: 8px;
}
.card-description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    max-height: 5.3em;
    overflow: hidden;
    margin: 0;
}
.proposal-status {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 0.1em;
    font-weight: 700;
}
.status-color {
    display: flex;
    /* align-items: center; */
    margin-right: 3px;
}
.status-text {
    font-size: 10px;
}

.card-tag-container {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    align-items: center;
    flex-wrap: wrap;
}
.card-individual-tag {
    padding: 0 5px;
    border-left: 1px solid rgba(255, 245, 231, 0.4);

    /* size: 12px; */
}
.comment-total {
    padding: 0px 0.5em 0px 0.5em;
}
.voting-numbers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2%;
}
.thumbs {
    display: flex;
    justify-content: space-between;
    /* width: 15%; */
    align-items: center;
    font-size: 1.4em;
}
.r {
    margin-left: 20px;
}
.l {
    margin-right: 20px;
}
.thumbs-image {
    height: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    font-size: 23.7949px;
    line-height: 29px;
}
.thumbs-image {
    height: 1em;
    margin-right: 5px;
}
.thumbs-image:last-child {
    margin-left: 5px;
}
.votes-tracker {
    font-style: normal;
    font-weight: 500;
    font-size: 0.85em;
    line-height: 174%;
    color: #ffffff;
    text-align: center;
    padding: 2%;
}
.address {
    font-size: 10px;
    /* margin: 0 8px; */
}
.planets-list-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.planets-list-cont span {
    font-style: normal;
    font-weight: 500;
    font-size: 0.85em;
    line-height: 174%;
    color: #ffffff;
}
.planets-list-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
}
.list-circle {
    display: flex;
    border-radius: 90px;
    background: transparent;
    backdrop-filter: blur(28px);
    width: 45px;
    height: 45px;
    padding: 20%;
    justify-content: center;
    align-items: center;
}
.planet-list-img {
    width: 100%;
}

.tooltip3 {
    position: relative;
    cursor: pointer;
}
.tooltip-text3 {
    position: absolute;
    font-size: 1em;
    transform: translateY(-65%);
    background-color: rgb(22, 21, 21);
    color: #fff;
    backdrop-filter: blur(29px);
    border: 1px solid rgba(156, 156, 169, 0.2);
    white-space: nowrap;
    padding: 3px 7px;
    border-radius: 10px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease;
}
.tooltip3:hover .tooltip-text3 {
    visibility: visible;
    opacity: 1;
}

/* MOBILE CSS */
@media only screen and (max-width: 797px) {
    /* PROPOSAL CARD DETAILS */
    .details-container-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .details-container-right {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
    }
    .dcr-date {
        border-right: none;
        margin-right: 0;
        padding: 0;
        font-size: 10px;
    }
    .address {
        border-right: none;
    }
    .expires-at {
        padding: 0;
        margin-right: 0;
        border-right: none;
    }
    .mid {
        display: flex;
        flex-direction: column;
        padding: 12px 0 14px 0;
    }
    .card-proposal-summary {
        width: 100%;
        margin-bottom: 2%;
    }
    .card-proposal-voting {
        width: 100%;
        margin: auto;
    }
    .tags-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: 12px;
    }
}
